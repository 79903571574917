html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scroll-behavior: smooth;
}

body > div#__next {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.sourceType {
  padding-bottom: 0;
}

.sourceType div {
  width: 100%;
  margin-right: 0;
}

.sourceType div:nth-child(n + 4) {
  flex: 1;
  margin-right: 1rem;
}

.sourceType div:nth-child(6) {
  margin-right: 0 !important;
}

.markdownContent p {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 36px;
  color: black;
}

.markdownContent h1,
.markdownContentListings h1 {
  font-size: 2.25rem;
  font-weight: 600;
  margin-block: 8px;
  color: black;
}

.markdownContent h2,
.markdownContentListings h2 {
  font-size: 1.875rem;
  font-weight: 500;
  margin-block: 8px;
  color: black;
}

.markdownContent h3,
.markdownContentListings h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-block: 8px;
  color: black;
}

.markdownContent h4,
.markdownContentListings h4 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-block: 8px;
  color: black;
}

.markdownContent h5,
.markdownContentListings h5 {
  font-size: 1rem;
  font-weight: 500;
  margin-block: 8px;
  color: black;
}

.markdownContent h6,
.markdownContentListings h6 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-block: 8px;
  color: black;
}

.markdownContent ul,
.markdownContentListings ul {
  list-style-type: disc;
  margin-block: 24px;
  margin-inline: 24px;
  font-size: 24px;
  color: black;
}
.markdownContentListings ul {
  margin-block: 18px;
  padding-inline-start: 18px;
  font-size: 24px;
  color: black;
}

.markdownContent ol,
.markdownContentListings ol {
  list-style-type: decimal;
  margin-block: 16px;
  margin-inline: 24px;
  list-style-position: outside;
  font-size: 24px;
  color: black;
}

.markdownContent blockquote,
.markdownContentListings blockquote {
  margin-top: 40px;
  margin-bottom: 32px;
  font-size: 0.875rem;
  font-weight: 400;
  border-left: 4px solid rgb(234, 234, 239);
  font-style: italic;
  padding: 8px 20px;
  color: black;
}

.markdownContent pre,
.markdownContent code,
.markdownContentListings pre,
.markdownContentListings code {
  font-size: 0.875rem;
  border-radius: 4px;
  background-color: rgb(50, 50, 77);
  max-width: 100%;
  overflow: auto;
  padding: 8px;
  color: black;
}

.markdownContent pre > code,
.markdownContentListings pre > code {
  color: rgb(131, 148, 150);
}

.markdownContent a,
.markdownContentListings a {
  color: #FB20AC;
  cursor: pointer;
  text-decoration: underline;
}

.markdownContent u,
.markdownContentListings u {
  text-decoration: underline;
}

.customerReviewSlider .slick-slide {
  padding: 0 10px;
}

.termsMarkdownContent p {
  font-size: 16px;
  color:  #6b7280;
  line-height: 20px;
  margin-bottom: 1.25rem;
}
.termsMarkdownContent h2 {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin:0;
  margin-top: 1.25rem;
  margin-bottom: 1.2rem;
}
.termsMarkdownContent h3 {
  margin-top: 1.25rem;
  margin-bottom: 1.2rem;
  font-weight:700;
  color:  #6b7280;

}
.termsMarkdownContent br {
  display: block;
  margin-top: .6rem;
  content: "";
}
.termsMarkdownContent ul {
  list-style-type: disc;
  padding-left: 20px;
}
.termsMarkdownContent ol {
  list-style-type: decimal;
  padding-left: 20px;
  color: #6b7280; 


}
.termsMarkdownContent ul li {
  color: #6b7280; 
  line-height: 1.6;
}

.termsMarkdownContent a {
  font-weight: 700;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(251, 32, 172, var(--tw-text-opacity));
}

.termsMarkdownContent h2 {
  margin-bottom: 20px;
  font-size: 1.25rem; 
  line-height: 1.75;
  font-weight: 500;
}

@media (min-width: 768px) {
  .termsMarkdownContent h2 {
    font-size: 1.5rem; 
    line-height: 2; 
  }
}

@media (min-width: 1024px) {
  .termsMarkdownContent h2 {
    margin-bottom: 28px; 
    font-size: 1.875rem; 
    line-height: 2.25; 
  }
}


@media (max-width: 500px) {
  .customerReviewSlider .slick-slide {
    padding: 0 7px;
  }
}

@media (max-width: 475px) {
  .customerReviewSlider .slick-slide {
    padding: 0 5px;
  }
}
